import React, { useState, useEffect } from 'react';

import ProjectHeader from "../../ui/project-header";
import Devster from "../../ui/svg/devster";

const DevsterProject = {
  component: DevsterPage,
  name: "Devster Mascot",
  description: "A square mascot that looks 'round",
  url: "/devster",
  tags: "SVG, React, Animation"
};

const DIRS = [
  undefined,
  "left",
  "up",
  "right",
  "down"
];

function DevsterPage() {
  const [direction, setDirection] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDirection((direction + 1) % DIRS.length)
    }, 500);

    return () => clearTimeout(timeout);
  });

  return (
    <div className="hd-project">
      <ProjectHeader title={DevsterProject.name} />
      <div className="hd-project-content" style={{textAlign: "center"}}>
        <div
          style={{
            display: "inline-block",
            padding: "2em",
            border: "2px solid #4A4C59",
            borderRadius: "6px",
            backgroundColor: "#ffffff",
          }}
        >
          <Devster scale={2} direction={DIRS[direction]}/>
        </div>
      </div>

      <ProjectHeader title="Learn More" subhead back={false}/>
      <div className="hd-project-learn">
        Nothing special here, just a React component containing an SVG that renders the appropriate group for the given direction.
      </div>
    </div>
  )
};

export default DevsterProject;
