import React, {FC} from "react";
import Section from "../section";
import {Project} from "../types";
import "./project-list.css";

interface ProjectListProps {
  projects?: Array<Project>;
}

const ProjectList: FC<ProjectListProps> = ({ projects = [] }) => {
  if (projects.length === 0) {
    return null;
  }

  return (
    <Section title="Projects" contentClassName="hd-projects-list">
      {projects}
    </Section>
  );
};

export default ProjectList;
