import React, { useEffect, useState } from "react";

import Cube from "./cube";
import InputNumber from "../../ui/inputs/input-number";
import "./editor.css";

function validateSize(v, size) {
  if (isNaN(v)) {
    return 32;
  }

  if (v % 4) {
    if (v < size) {
      return v - (v % 4);
    }

    return v + (4 - (v % 4));
  }

  return v;
}

function validateScale(v) {
  if (isNaN(v)) {
    return 8;
  }

  return v;
}

export default function Editor(props) {
  const [size, setSize] = useState(validateSize(props.size, props.size));
  const [scale, setScale] = useState(validateScale(props.scale));

  useEffect(() => {
    if (props.size) {
      setSize(
        validateSize(props.size, size)
      );
    }
  }, [props.size, size]);

  useEffect(() => {
    setScale(
      validateScale(props.scale)
    );
  }, [props.scale]);

  return (
    <div className="hd-editor ic-editor">
      <div className="hd-editor-input ic-inputs">
        <InputNumber
          label="Size"
          value={size}
          min={0}
          onChange={v => {
            const value = validateSize(v, size);

            setSize(value);
            props.onChange(value, scale);
          }}
          helper="Perfect squares (4, 8, 16, 32) work best, minimum size of 4px"
        />
        <InputNumber
          label="Scale"
          value={scale}
          min={1}
          onChange={v => {
            const value = validateScale(v);

            setScale(value);
            props.onChange(size, value);
          }}
          helper="The scaling ratio of a single 'pixel'"
        />
      </div>
      <div className="hd-editor-preview ic-preview">
        <Cube
          scale={scale}
          size={size}
          relative={true}
        />
      </div>
    </div>
  );
}

Editor.defaultProps = {
  size: 32,
  scale: 8,
  onChange: () => {}
};
