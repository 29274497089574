import React, {FC} from "react";

interface SBLinkProps {
  url: string;
  name: string;
}

const SBLink: FC<SBLinkProps> = ({ name, url, children }) => (
  <li>
    {children && (
      <React.Fragment>
        <a href={`https://heyer.dev/storybook/?path=/story/${url}`}>
          <span className="hd-sitemap-link">
            {name}
          </span>
        </a>
        <ul>
          {children}
        </ul>
      </React.Fragment>
    )}

    {!children && (
      <React.Fragment>
        <a href={`https://heyer.dev/storybook/?path=/story/${url}`}>
          <span className="hd-sitemap-link">
            {name}
          </span>
          <span className="hd-sitemap-link-tag white">
            Story
          </span>
        </a>
        <a className="hd-sitemap-child" href={`https://heyer.dev/storybook/iframe.html?id=${url}`}>
          <span className="hd-sitemap-link">
            {name}
          </span>
          <span className="hd-sitemap-link-tag white">
            Direct
          </span>
        </a>
      </React.Fragment>
    )}
  </li>
);

export default SBLink;
