export default class IsometricCanvas {
  constructor(ctx, scale, outlineColor, inlineColor) {
    this.ctx = ctx;
    this.one = scale;
    this.two = scale * 2;

    this.outlineColor = outlineColor;
    this.inlineColor = inlineColor;
  }

  getStroke(conditional) {
    return conditional ? this.outlineColor : this.inlineColor;
  }

  fillPixel(x, y) {
    this.ctx.fillRect(x, y, this.two, this.one);
  }

  drawDiagonal(x, y, vertical, steps, conditional = () => true) {
    const oldFill = this.ctx.fillStyle;
    const color = this.getStroke(conditional);
    const dir = vertical ? 1 : -1;

    this.ctx.fillStyle = color;
    for (let i = 0; i < steps; i++) {
      this.fillPixel(
        this.one + x + (i * this.two),
        y + (dir * i * this.one),
        color
      );
    }

    this.ctx.fillStyle = oldFill;
  }

  drawLine(x, y, width, height, conditional = () => true) {
    const oldFill = this.ctx.fillStyle;
    const color = this.getStroke(conditional);

    this.ctx.fillStyle = color;
    this.ctx.fillRect(x, y, width, height);
    this.ctx.fillStyle = oldFill;
  }

  fillTop(x, y, steps, color) {
    const oldFill = this.ctx.fillStyle;

    this.ctx.fillStyle = color;
    for (let i = 0; i < steps; i++) {
      this.ctx.fillRect(
        x + (i * this.two),
        y - ((i - 1) * this.one),
        this.two,
        i * this.two
      );
    }

    for (let i = steps - 2; i > 0; i--) {
      this.ctx.fillRect(
        x + (((steps * 4) - 4) * this.one) - (i * this.two),
        y - ((i - 1) * this.one),
        this.two,
        i * this.two
      );
    }

    this.ctx.fillStyle = oldFill;
  }

  fillSide(x, y, vertical, steps, color) {
    const dir = vertical ? 1 : -1;
    const oldFill = this.ctx.fillStyle;

    this.ctx.fillStyle = color;

    for (let i = 0; i < steps; i++) {
      this.ctx.fillRect(
        x + (i * this.two),
        y + this.one + (dir * i * this.one),
        this.two,
        this.two * steps - this.one
      );
    }

    this.ctx.fillStyle = oldFill;
  }
}
