import React from "react";
import { Route } from "react-router-dom";
import Project from "../ui/project";

export default function getProjects(projects) {
  const res = {
    routes: [],
    projectComponents: [],
    tags: {}
  };

  return projects.reduce((acc, p) => {
    if (!p.disabled) {
      const name = p.name.replace(/[ ?!.]+/, "");
      const tags = p.tags.split(/,[ ]+/g);

      tags.forEach(tag => {
        const t = tag.toLowerCase();

        acc.tags[t] = acc.tags[t] || {
          name: tag,
          projects: [],
          id: t,
          count: 0
        };

        acc.tags[t].count++;
        acc.tags[t].projects.push(p);
      });

      if (p.route !== false) {
        acc.routes.push(
          <Route
            path={p.path || p.url}
            component={p.component}
            key={`${name}-route`}
          />
        );
      }

      acc.projectComponents.push(
        <Project
          name={p.name}
          description={p.description}
          url={p.url}
          key={`${name}-project`}
          tags={p.tags}
          route={p.route}
        />
      );
    }

    return acc;
  }, res);
}
