import React from "react";
import InputContainer from "../../ui/inputs/input-container";
import InputNumber from "../../ui/inputs/input-number";

const ArmControls = ({ index, length, speed, rotation, setLength, setSpeed, setRotation }) => (
  <InputContainer label={`Arm ${index} Settings`}>
    <InputNumber
      label="Length"
      value={length}
      min={1}
      max={200}
      onChange={setLength}
    />
    <InputNumber
      label="Speed"
      value={speed}
      min={1}
      max={50}
      onChange={setSpeed}
    />
    <InputNumber
      label="Rotation"
      value={rotation}
      min={index === 1 ? -180 : 0}
      max={180}
      onChange={setRotation}
    />
  </InputContainer>
);

export default ArmControls;
