import React, {FC} from "react";
import "./section.css";

export interface SectionProps {
  id?: string;
  title?: string;
  className?: string;
  contentClassName?: string;
}

/**
 *
 */
const Section: FC<SectionProps> = ({
  title = "",
  className = "",
  contentClassName = "",
  id = "",
  children
}) => {
  const internalId = id ? id : title.toLowerCase().replace(/w+/g, "-");

  return (
    <div id={internalId} className={`hd-section ${internalId} ${className}`}>
      <h3 className={`hd-section-header hd-header-${internalId}`}>
        <div className="line"> </div>
        <span>
          {title}
        </span>
      </h3>
      <div className={`hd-section-content hd-content-${internalId} ${contentClassName}`}>
        {children}
      </div>
    </div>
  );
}

export default Section
