import { BaseEntity, Vector } from "2d-engine/src";
import Two from "two.js";

export default class Arm extends BaseEntity {
  initializeProperties(options) {
    options = options || {};

    super.initializeProperties(options);

    this.armLength = options.armLength || 100;
    this.rotationSpeed = options.rotationSpeed || 10;
    this.rotationLimit = options.rotationLimit || Math.PI;
    this.startRotation = (options.startRotation || 0) * 180 / (Math.PI);
    this.positiveRotation = options.startPositive === false ? options.startPositive : true;
  }

  updateBasic(delta) {
    if (!this.engine.paused) {
      const currRotation = this.element.rotation;
      const rotationAmount = ((delta * this.rotationSpeed) / 60000) * Math.PI;
      let newRotation;

      if (this.parentArm) {
        const pos = new Vector(
          this.xScale.invert(this.parentArm.circle2.translation.x),
          this.yScale.invert(this.parentArm.circle2.translation.y)
        );

        this.pos.x = pos.x;
        this.pos.y = pos.y;
      }

      if (this.positiveRotation) {
        if (currRotation + rotationAmount > this.rotationLimit / 2) {
          this.positiveRotation = false;
          newRotation = currRotation - ((Math.PI / 2) - (currRotation + rotationAmount));
        }
        else {
          newRotation = currRotation + rotationAmount;
        }
      }
      else {
        if (currRotation - rotationAmount < this.rotationLimit / -2) {
          this.positiveRotation = true;
          newRotation = currRotation + ((currRotation - rotationAmount) + (Math.PI / 2));
        }
        else {
          newRotation = currRotation - rotationAmount;
        }
      }

      if (!this.parentArm) {
        this.element.rotation = ((this.element.rotation || 0) + rotationAmount) % (2 * Math.PI)
      }
      else {
        this.element.rotation = newRotation;
      }

      if (!this.parentArm) {
        let curr = this;

        let point = new Vector(
          this.xScale(0),
          this.yScale(0)
        );

        point.rotate(this.element.rotation);

        let rotation = 0;

        while(curr) {
          const currPoint = new Vector(curr.circle2.translation.x, curr.circle2.translation.y);
          rotation += curr.element.rotation;
          currPoint.rotate(rotation);
          point.plusEquals(currPoint);
          curr = curr.childArm;
        }

        if (!this.line) {
          this.line = this.canvas.makePath(point.x, point.y, true);
          this.line.stroke = "#00ff00";
          this.line.fill = "none";
          this.line.linewidth = 1;
          this.line.translation.set(this.xScale(400), this.yScale(400));
        }
        else {
          this.line.vertices.push(new Two.Anchor(point.x, point.y));
        }
      }
    }

    super.updateBasic(delta);
  }

  render(canvas) {
    if (this.element) {
      this.circle2.translation.set(
        this.xScale(0),
        this.yScale(-1 * this.armLength)
      );

      this.bar.height = this.yScale(this.armLength);
      this.bar.translation.set(this.xScale(0), this.yScale(-.5 * this.armLength));
      this.element.rotation = this.startRotation;

      let curr = this.childArm;

      while (curr) {
        this.childArm.render();
        curr = curr.childArm;
      }
    }
    else {
      this.canvas = canvas;
      this.armGroup = canvas.makeGroup();

      this.pos.x = 400;
      this.pos.y = 400;

      this.circle1 = canvas.makeCircle(this.xScale(0), this.yScale(0), this.scale(10));
      this.circle1.fill = "#8C8880";
      this.circle1.stroke = "none";

      this.circle2 = canvas.makeCircle(this.xScale(0), this.yScale(-1 * this.armLength), this.scale(10));
      this.circle2.fill = "#8C8880";
      this.circle2.stroke = "none";

      this.bar = canvas.makeRectangle(this.xScale(0), this.yScale(-.5 * this.armLength), this.xScale(8), this.yScale(this.armLength));
      this.bar.fill = "#8C8880";
      this.bar.stroke = "none";

      if (!this.parentArm) {
        this.canvas.makeCircle(this.xScale(400), this.yScale(400), this.scale(200));
      }

      this.element = canvas.makeGroup(
        this.circle1,
        this.circle2,
        this.bar
      );

      if (this.parentArm) {
        this.parentArm.element.add(this.element);
        this.parentArm.childArm = this;
      }

      this.translateByPoint(this.pos);
      console.log(this.startRotation);
      this.element.rotation = this.startRotation;
    }
  }
}
