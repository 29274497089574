import React from "react";
import Engine from "./engine";
import "./styles.css";

import ProjectHeader from "../../ui/project-header";

// const RATIO = 5;
// const WORLD_WIDTH = 600;
// const WORLD_HEIGHT = WORLD_WIDTH / RATIO;
// const RADIUS = 25;

const DrawArmPage = () => {
  return (
    <div className="hd-project">
      <ProjectHeader title={DrawArmProject.name} />
      <div className="hd-project-content" style={{textAlign: "center"}}>
        <Engine />
      </div>

      <ProjectHeader subhead back={false}>
        Learn More
      </ProjectHeader>
      <div className="hd-project-learn">
        Coming Soon!
      </div>
    </div>
  );
};

const DrawArmProject = {
  component: DrawArmPage,
  name: "Multi Jointed Drawing Arm",
  description: "Coming Soon",
  url: "/draw-arm",
  tags: "Physics, Animation, Simulation"
};

export default DrawArmProject;
