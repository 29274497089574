import React, {FC} from "react";
import { Link } from "react-router-dom";

import {Project} from "../types";

type ProjectLinkProps = Pick<Project, "name" | "url">;

const ProjectLink: FC<ProjectLinkProps> = ({ name, url }) => (
  <li>
    <Link to={url}>{name}</Link>
  </li>
);

export default ProjectLink
