import React, { useMemo, useEffect } from "react";
import { addBalls } from "2d-engine-bounce";

import useEngine from "../../lib/use-engine";
import InputNumber from "../../ui/inputs/input-number";
import "./bounce.css";

const DEFAULTS = {
  gravity: 9.8,
  ballAbsorbtion: .9,
  wallAbsorbtion: .98,
  ballCount: 100
};

const getClientWidth = e => e.clientWidth * .8;
const getClientHeight = e => e.clientWidth * .8 * .6;

const Engine = ({ id = 1 }) => {
  const startingOptions = useMemo(() => ({
    getClientWidth,
    getClientHeight,
    worldWidth: 1000,
    worldHeight: 600,
    id
  }), [id]);

  const [options, engine, setRef, reset, setters] = useEngine(
    startingOptions,
    DEFAULTS
  );

  useEffect(() => {
    if (engine) {
      engine.stop();
      engine.clear();

      addBalls(engine, options.ballCount, options);

      engine.start();
    }
  }, [engine, options])

  useEffect(() => {
    if (engine) {
      addBalls(engine, options.ballCount, options);

      engine.zoomEnabled = false;
      engine.start();
    }
  }, [engine]);

  return (
    <div className="hd-editor hd-bounce">
      <div className="hd-editor-inputs">
        <InputNumber
          label="Ball Count"
          value={options.ballCount}
          min={1}
          onChange={setters.ballCount}
          helper="Number of balls created"
        />

        <InputNumber
          label="Gravity"
          value={options.gravity}
          min={0}
          onChange={setters.gravity}
          helper="The force of gravity, default is 9.8"
          float
        />

        <InputNumber
          label="Ball Absorbtion"
          value={options.ballAbsorbtion}
          min={0}
          onChange={setters.ballAbsorbtion}
          helper="Amount of energy maintained when two balls collide.Values less than one will gradually reduce a balls energy, a value of one will maintain the current energy and values greater than one will increase energy every collision."
          float
        />

        <InputNumber
          label="Wall Absorbtion"
          value={options.wallAbsorbtion}
          min={0}
          onChange={setters.wallAbsorbtion}
          helper="Amount of energy maintained when a ball collides with the wall. Values less than one will gradually reduce a balls energy, a value of one will maintain the current energy and values greater than one will increase energy every collision."
          float
        />

        <input type="button" className="input-input" value="Reset" onClick={reset} />
      </div>
      <div ref={setRef} className={`hd-editor-preview-canvas engine-${id}`}> </div>
    </div>
  );
}

export default Engine;
