import React, {FC} from "react";

import { Link } from "react-router-dom";
import "./project-header.css";

export interface ProjectHeaderProps {
  /**
   * The header's text
   */
  title: string;

  /**
   * Whether or not this is a subheader, if so the numeric level of the header
   * `true` defaults to level 1
   */
  subhead?: number | boolean;

  /**
   * Whether or not to show the back button
   */
  back?: boolean;
}

const ProjectHeader: FC<ProjectHeaderProps> = ({
  children,
  title,
  subhead = false,
  back = true
}) => {
  let classnames = ["hd-project-header"];

  if (subhead) {
    classnames.push("hd-project-subheader");

    if (subhead !== true) {
      classnames.push("hd-project-subheader-" + Number(subhead));
    }
  }

  return (
    <div>
      {!subhead && back && (
        <Link className="hd-masthead" title="Return to heyer.dev by Brandon Heyer" to="/">
          <div className="hd-header">
            <span className="hd-heyer">heyer</span>
            <span className="hd-dot">.</span>
            <span className="hd-dev">dev</span>
          </div>
        </Link>
      )}
      <h3 className={classnames.join(" ")}>
        {subhead <= 2 &&
          <div className="line"> </div>
        }
        <span>{ title || children }</span>

        {subhead < 2 && back && (
          <Link
            className="hd-project-back"
            to="/"
          >
            <strong className="hd-project-back-chevron">&lt;</strong> Back
          </Link>
        )}
      </h3>
    </div>
  )
};

export default ProjectHeader;
