import React, {FC} from "react";
import "./header.css";

/**
 * The header for the main page
 */
const Header: FC = () => (
  <div id="hd-main" className="hd-main">
    <h1 className="hd-header">
      <div>
        <span className="hd-heyer">heyer</span>
        <span className="hd-dot">.</span>
        <span className="hd-dev">dev</span>
      </div>
      <span className="coming-soon">
        Introducing
      </span>
    </h1>
    <div className="red-dark-bg line"> </div>
    <div className="green-dark-bg line"> </div>
  </div>
);

export default Header;
