import React, { useMemo, useEffect } from "react";
import { Point } from "2d-engine/src";

import Planet from "./planet";
import useEngine from "../../lib/use-engine";
import "./gravity.css";


const BAND_COLORS = [
  "rgb(245, 8, 82)",
  "rgb(240, 100, 8)",
  "rgb(136, 240, 8)",
  "rgb(8, 240, 158)",
  "rgb(8, 50, 240)"
];
const GEE = 1;
const UNIT = 500;
const PLANET_MAG = 25;
const I_MAX = 5;
const J_MAX = 18;
const STARTING_RADIUS_BAND = 20;
const worldWidth = UNIT * 500;
const worldHeight = UNIT * 300;

const getClientWidth = e => e.clientWidth * .8;
const getClientHeight = e => e.clientWidth * .8 * .6;

const makePlanet = (engine) => (headingDegrees, headingMagnitude, positionDegrees, positionMagnitude = 50, mass = 100, band) =>
  new Planet({
    engine,
    fixed: false,
    mass,
    radius: mass * 0.95,
    headingDegrees,
    headingMagnitude,
    positionDegrees,
    positionMagnitude: positionMagnitude * UNIT,
    fill: BAND_COLORS[band]
  });

const Engine = ({ id = 1 }) => {
  const startingOptions = useMemo(() => ({
    getClientWidth,
    getClientHeight,
    worldWidth,
    worldHeight,
    id
  }), [id]);

  const [options, engine, setRef, reset] = useEngine(
    startingOptions
  );

  useEffect(() => {
    if (engine) {
      engine.stop();
      engine.clear();
      engine.GEE = GEE;

      // const planet2 = new Planet({ engine: this, fixed: false, mass: 1000, radius: 20 });

      const sun = new Planet({ engine: this, fixed: true, mass: 330000000, radius: 5000, fill: 'rgb(250, 228, 81)' });
      sun.pos.x = worldWidth / 2;
      sun.pos.y = worldHeight / 2;
      engine.addEntity(sun);
      sun.entities = engine.entities;
      sun.initialize();

      const planets = [];
      const mp = makePlanet(engine);

      for (let i = 0; i < I_MAX; i++) {
        const baseRadius = i > I_MAX / 2 ? 120 : 60;
        // const radius = 80 * (((30 + i > (I_MAX / 2) ? 10 : 30) * Math.random()) - 15)

        for (let j = 0; j < (i + 1) * (J_MAX - (i * 4)); j++) {
          const randomness = baseRadius / 4 * (I_MAX - i) / 2;
          const radius = baseRadius * (randomness * Math.random() - (randomness / 2));
          const max = (i + 1) * (J_MAX - (i * 4));

          planets.push(
            mp(
              ((j + 1) * 360 / max) + 90 + (10 * Math.random() - 5),
              // PLANET_MAG * ((i + (1 * (1 + (i/10)))) * (magMultiplier / (1 + i))),
              PLANET_MAG - (i * i * i * .88 * (Math.pow(.75, i + 1))),
              360 - ((j + 1) * ((360 / max) + (.25 * Math.random() - .125))),
              STARTING_RADIUS_BAND + (15 * (i + 1)),
              radius,
              i
            )
          );
        }
      }

      planets.forEach(p => {
        engine.addEntity(p);
        p.entities = engine.entities;
        p.initialize();
      })

      engine.zoomEnabled = false;
      engine.start();
    }
  }, [engine, options]);

  window.engine = engine;
  console.log(engine);

  return (
    <div className="hd-editor hd-gravity">
      <div className="hd-editor-inputs">
        <input type="button" className="input-input" value="Reset" onClick={reset} />
      </div>
      <div ref={setRef} className={`hd-editor-preview-canvas engine-${id}`}> </div>
    </div>
  );
}

export default Engine;
