import React from "react";

import Engine from "./engine";
import ProjectHeader from "../../ui/project-header";

const GravityPage = () => (
  <div className="hd-project">
    <ProjectHeader title={GravityProject.name} />
    <div className="hd-project-content" style={{textAlign: "center"}}>
      {<Engine />}
    </div>

    <ProjectHeader subhead back={false}>
      Learn More
    </ProjectHeader>
    <div className="hd-project-learn">
      Details coming soon!
    </div>
  </div>
);

const GravityProject = {
  component: GravityPage,
  name: "Gravity Physics",
  description: "Simple 2D gravitational force simulations",
  url: "/gravity",
  tags: "Physics, Animation, Game, Simulation, Space, Force"
};

export default GravityProject;
