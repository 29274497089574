import React from "react";
import { random, seedrandom, TwoEngine, Point, Vector, BaseEntity } from "2d-engine/src";
import { BallEntity } from "2d-engine-bounce";
import EducationBall from "./educational-ball";

import InputNumber from "../../ui/inputs/input-number";

var id = 0;

export default class Engine extends React.Component {
  constructor(props) {
    super(props);

    this.id = id++;

    this.engineContainer = React.createRef();

    this.reset = this.reset.bind(this);
    this.pause = this.pause.bind(this);
    this.start = this.start.bind(this);

    this.ballOptions = [];

    (props.ballOptions || []).forEach((o, i) => {
      const res = o(props.width, props.height);
      this.ballOptions.push(Object.assign({}, {
        headingX: 0,
        headingY: 0,
        positionX: 0,
        positionY: 0
      }, res));
    });

    this.state = { ready: false };
  }

  init() {
    this.engine.stop();
    this.engine.clear();

    const options = {
      engine: this.engine,
      gravity: 9.8,
      ballAbsorbtion: .9,
      wallAbsorbtion: .98
    };

    this.ballOptions.forEach((o, i) => {
      const entity = new EducationBall(Object.assign(options, {
        radius: o.radius || this.props.height / 10,
        startingPosition: new Point(o.positionX || 0, o.positionY || 0)
      }));

      entity.heading = new Vector(o.headingX || 0, o.headingY || 0);
      entity.index = i;
      entity.log = true;

      this.engine.addEntity(entity);
    });

    this.setState({ ready: true });
  }

  componentDidMount() {
    const clientWidth = this.engineContainer.current.clientWidth || Infinity;
    const width = Math.min(clientWidth, 900) * .75;

    const engine = this.engine = new TwoEngine(
      `.engine-demo-${this.id}`,
      width, width / (this.props.width / this.props.height),
      this.props.width, this.props.height
    );
    const oldProcess = engine.process;
    const that = this;

    engine.zoomEnabled = false;
    engine.process = function(delta) {
      oldProcess.call(engine, delta);

      const shouldStop = this.entities.every(e => {
        if (e.pos.x < 0 || e.pos.x > that.props.width || e.pos.y < 0 || e.pos.y > that.props.height) {
          return true;
        }

        return e.heading.x < 0.01 &&
          e.heading.x > -0.01 &&
          e.heading.y < 0.01 &&
          e.heading.y > -0.01;
      });

      if (shouldStop) {
        that.init();
        this.start();
        setTimeout(() => requestAnimationFrame(() => this.stop()), 1);
      }
    }.bind(engine);

    this.init();
    this.engine.start();
    requestAnimationFrame(() => this.engine.stop());
  }

  reset() {
    this.init();
    this.engine.start();
    requestAnimationFrame(() => this.engine.stop());
  }

  start() {
    this.engine.start();
    this.setState({ ready: false });
  }

  pause() {
    this.engine.stop();
    this.setState({ ready: true });
  }

  render() {
    return (
      <div className="hd-editor hd-bounce hd-bounce-example">
        <div ref={this.engineContainer} className={`hd-editor-preview-canvas engine-demo-${this.id}`}> </div>

        <div className="hd-editor-controls">
          <input
            type="button"
            className="input-input"
            value="Pause"
            disabled={this.state.ready}
            onClick={this.engine && this.pause}
          />
          <input
            type="button"
            className="input-input"
            value={this.state.ready ? "Start" : "Reset" }
            onClick={this.state.ready ? this.start : this.reset}
          />
        </div>
      </div>
    )
  }
}
