import { BaseEntity, Vector } from "2d-engine/src";
import { BallEntity } from "2d-engine-bounce";

export default class EducationalBall extends BallEntity {
  postUpdatePosition(delta) {
    BaseEntity.prototype.postUpdatePosition.call(this, delta);

    const endPoint = this.heading.getNormalized();
    endPoint.x *= this.radius;
    endPoint.y *= this.radius;
    endPoint.scalePlusEquals(8, this.heading);

    this.headingLine.vertices[1].x = this.xScale(endPoint.x);
    this.headingLine.vertices[1].y = this.yScale(endPoint.y);
  }

  preUpdatePosition() {}

  onCollision(other, normal, magnitude) {
    super.onCollision(other, normal, magnitude);

    this.fade(
      this.createDisplayLine(
        this.pos.x,
        this.pos.y,
        other.pos.x,
        other.pos.y,
        2,
        "#000000",
        0.8
      )
    );

    const diffVec = new Vector(other.pos.x - this.pos.x, other.pos.y - this.pos.y);
    const normalHeading = diffVec.getNormalized().times(this.radius);
    const scaledPos = this.pos.plus(normalHeading);

    const tangentNormal = new Vector(normal.y * -1 * (magnitude / 2), normal.x * (magnitude / 2));
    const tangentPos = scaledPos.plus(tangentNormal);

    tangentNormal.negate();

    const otherEnd = scaledPos.plus(tangentNormal);

    this.fade(
      this.createDisplayLine(
        tangentPos.x,
        tangentPos.y,
        otherEnd.x,
        otherEnd.y,
        2,
        "#000000",
        0.8
      )
    );
  }

  fade(obj) {
    setTimeout(() => {
      obj.opacity -= .01;

      if (obj.opacity < 0) {
        obj.remove();
      }
      else {
        this.fade(obj);
      }
    }, 50);
  }

  createDisplayLine(x1, y1, x2, y2, width, color, opacity = 1) {
    const line = this.canvas.makeLine(
      this.xScale(x1),
      this.yScale(y1),
      this.xScale(x2),
      this.yScale(y2)
    );

    line.stroke = color;
    line.linewidth = this.scale(width);
    line.opacity = opacity;

    return line;
  }

  render(canvas) {
    this.canvas = canvas;
    this.circle = canvas.makeCircle(0, 0, this.xScale(this.radius));
    this.circle.fill = "#8C8880";
    this.circle.stroke = "none";

    this.headingLine = this.createDisplayLine(
      0, 0, 0, 0,
      this.radius / 2,
      "#A60505"
    );

    this.element = canvas.makeGroup(
      this.headingLine,
      this.circle
    );
  }
}
