import React, { useMemo } from "react";
import SVG from "./svg";
import data from "./100-80-60-80-60-arm-check.json";
import "./styles.css";

import ProjectHeader from "../../ui/project-header";

const START_POSITIONS = [];
START_POSITIONS.length = 720;
START_POSITIONS.fill(0);


const ArticulatingClockPage = () => {
  const positions = useMemo(() => START_POSITIONS.reduce(
    (acc, d, i) => {
      const key = `${Math.floor(i / 60) + 1}:${(i % 60) + 1}`;

      acc.push(data.pruned[key][d]);

      return acc;
    }, []
  ), []);

  return (
    <div className="hd-project articulating-arm">
      <ProjectHeader title={ArticulatingClockProject.name} />
      <div className="hd-project-content">
        <SVG positions={positions} animate />
      </div>

      <ProjectHeader subhead back={false}>
        Project Details
      </ProjectHeader>
      
      <ProjectHeader subhead="2" back={false}>
        Background
      </ProjectHeader>
      <div className="hd-project-learn">
        Coming Soon
      </div>
    </div>
  );
};

// <pre>
//   {JSON.stringify(positionIndices)}
// </pre>

// {Object.keys(data.pruned).map((k,i) => (
//   <div className="time-svgs" key={i}>
//     <div className="time">${k}</div>
//     {data.pruned[k].map((entry, j) => (
//       <div className="entry" key={j}>
//         <SVG positions={entry}
//           selected={positionIndices[i] === j}
//           onClick={() => setPositionIndices((prev) => { prev[i] = j; return [...prev]; })}
//         />
//       </div>
//     ))}
//   </div>
// ))}

const ArticulatingClockProject = {
  component: ArticulatingClockPage,
  name: "Articulating Clock",
  description: "Rhaspberry Pi powered physical representations of time",
  url: "/articulating-clock",
  tags: "Physics, Animation, Simulation, Rhaspberry Pi, Arduino"
};


export default ArticulatingClockProject;
