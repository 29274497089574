import React, {useState, useCallback} from "react";
import _debounce from "lodash.debounce";
import encodeLabel from "./encode-label";
import InputLabel from "./input-label";
import "./input-number.css";

const outerOnChange = (float, min, max, onChange, setTempVal) => {
  const changer = _debounce((v) => {
    onChange(v);
    setTempVal(v);
  }, 200);

  return (e) => {
    const parseMethod = float ? parseFloat : parseInt;
    const value = parseMethod(e.target.value);
    let prev;

    setTempVal((pv) => {
      prev = pv;

      if (
        !isNaN(value) && (
          (value >= min) &&
          (value <= max)
        )
      ) {
        changer(value);
      } else {
        if (value < min) {
          changer(min);
        }

        else if (value > max) {
          changer(max);
        }
        else {
          changer(prev);
        }
      }

      return isNaN(value) ? "" : value;
    });
  };
};

export default function InputNumber({
  value,
  float,
  min,
  max,
  step,
  label,
  helper,
  onChange = () => {}
}) {
  const [tempVal, setTempVal] = useState(value);
  const innerOnChange = useCallback(
    outerOnChange(float, min, max, onChange, setTempVal),
    [float, min, max, onChange]
  );

  return (
    <div className="input-container">
      <div className="input input-number">
        <InputLabel label={label} />
        <input
          type="number"
          className="input-input"
          key="input-input"
          value={tempVal}
          onChange={innerOnChange}
          step={step ? step : (float ? 0.1 : 1)}
          min={min}
          max={max}
          id={encodeLabel(label)}
        />
      </div>

      <InputLabel
        className="input-helper"
        htmlFor={encodeLabel(label)}
        label={helper}
      />
    </div>
  );
}

InputNumber.defaultProps = {
  min: -Infinity,
  max: Infinity,
  label: "",
  onChange: () => {},
  float: false
}
