import React, {FC} from "react";
import { Link } from "react-router-dom";

interface ProjectTagProps {
  tag: string;
}

const ProjectTag: FC<ProjectTagProps> = ({ tag }) => (
  <Link
    to={`/tags/${tag.toLowerCase()}`}
    key={tag}
    className="hd-project-tag"
  >
    {tag}
  </Link>
);

export default ProjectTag;
