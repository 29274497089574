import React, { useEffect, useState, useRef, useCallback } from "react";

import InputNumber from "../../ui/inputs/input-number";
import useEngine from "../../lib/use-engine";
import ArmControls from "./arm-controls";
import Arm from "./arm";

import "./draw-arm.css";

const DEFAULTS = {
  arm1Length: 100,
  arm1Speed: 5,
  arm1Rotation: 0,
  arm2Length: 50,
  arm2Speed: 4,
  arm2Rotation: 0,
  arm3Length: 40,
  arm3Speed: 3,
  arm3Rotation: 0,
  arm4Length: 30,
  arm4Speed: 2,
  arm4Rotation: 0,
  arm5Length: 20,
  arm5Speed: 1,
  arm5Rotation: 0,
};

const getClientWidth = e => e.clientWidth * .8;
const getClientHeight = e => e.clientWidth * .8;

const Engine = ({ id = 1 }) => {
  const [arms, setArms] = useState([]);
  const [paused, setPaused] = useState(true);

  const [options, engine, setRef, reset, setters] = useEngine(
    {
      getClientWidth,
      getClientHeight,
      worldWidth: 800,
      worldHeight: 800,
      id
    },
    DEFAULTS,
    () => {},
    (key, value) => {
      const matches = /arm([0-9])(.*)/.exec(key);

      if (matches) {
        const index = parseInt(matches[1], 10) - 1;

        if (matches[2] === "Length") {
          arms[index].armLength = value;
        }
        else if (matches[2] === "Speed") {
          arms[index].rotationSpeed = value;
        }
        else if (matches[2] === "Rotation") {
          arms[index].startRotation = (value * (Math.PI / 180)) % Math.PI;
        }

        arms[index].render();
      }
    }
  );

  const togglePaused = useCallback(() => {
    if (engine.paused && !engine.running) {
      engine.start();
    }

    setPaused((prev) => {
      engine.paused = !prev;

      return !prev;
    });
  }, [engine]);

  useEffect(() => {
    if (engine) {
      if (!engine.running) {
        const newArms = [];

        engine.removeAllEntities();
        engine.paused = true;
        engine.zoomEnabled = false;

        newArms.push(new Arm({
          armLength: options.arm1Length,
          rotationSpeed: options.arm1Speed,
          startRotation: options.arm1Rotation,
          startPositive: true,
          engine,
        }));
        engine.addEntity(newArms[0]);

        newArms.push(new Arm({
          armLength: options.arm2Length,
          rotationSpeed: options.arm2Speed,
          startRotation: options.arm2Rotation,
          startPositive: true,
          engine,
        }));
        newArms[1].parentArm = newArms[0];
        engine.addEntity(newArms[1]);

        newArms.push(new Arm({
          armLength: options.arm3Length,
          rotationSpeed: options.arm3Speed,
          startRotation: options.arm3Rotation,
          startPositive: true,
          engine
        }));
        newArms[2].parentArm = newArms[1];
        engine.addEntity(newArms[2]);

        newArms.push(new Arm({
          armLength: options.arm4Length,
          rotationSpeed: options.arm4Speed,
          startRotation: options.arm4Rotation,
          startPositive: true,
          engine,
        }));
        newArms[3].parentArm = newArms[2];
        engine.addEntity(newArms[3]);

        newArms.push(new Arm({
          armLength: options.arm5Length,
          rotationSpeed: options.arm5Speed,
          startRotation: options.arm5Rotation,
          startPositive: true,
          engine,
        }));
        newArms[4].parentArm = newArms[3];
        engine.addEntity(newArms[4]);

        setArms(newArms);
        engine.start();
        engine.paused = false;
        engine.process(0);
        engine.paused = true;
      }
    }

  }, [engine]);

  return (
    <div className="hd-editor hd-bounce">
      <div className="hd-editor-inputs">
        {arms.map((a, i) => (
          <ArmControls
            key={i}
            index={i + 1}
            length={options[`arm${i + 1}Length`]}
            setLength={setters[`arm${i + 1}Length`]}
            speed={options[`arm${i + 1}Speed`]}
            setSpeed={setters[`arm${i + 1}Speed`]}
            rotation={options[`arm${i + 1}Rotation`]}
            setRotation={setters[`arm${i + 1}Rotation`]}
          />
        ))}

        <input type="button" className="input-input" value={paused ? "Start" : "Pause"} onClick={togglePaused} />
        <input type="button" className="input-input" value="Reset" onClick={reset} />
      </div>
      <div ref={setRef} className={`hd-editor-preview-canvas engine-${id}`}> </div>
    </div>
  );
}

export default Engine;
