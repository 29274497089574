import React, {FC} from "react";
import { Link } from "react-router-dom";

import {Tag} from "../types";

type TagLinkProps = Pick<Tag, "name" | "id" | "count">;

const TagLink: FC<TagLinkProps> = ({ name, id, count }) => (
  <li>
    <Link to={`/tags/${id}`}>
      {name}

      <span className="hd-sitemap-tag-count blue-dark-bg white">
        {count}
      </span>
    </Link>
  </li>
);

export default TagLink;
