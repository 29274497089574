import React, {FC} from "react";
import {RouteComponentProps} from "react-router-dom";
import ProjectHeader from "../project-header";
import Project from "../project";
import { Tag } from "../types";

interface TagLookup {
  [name: string]: Tag
};

interface RouteParams {
  tag: string;
}

export interface TagPageProps {
  /**
   * The tag to display projects for
   */
  tag: Tag;
};

const TagPage: FC<TagPageProps> = ({ tag }) => {
  if (!tag) {
    return null;
  }

  return (
    <div className="hd-project hd-tag-page">
      <ProjectHeader title={`Tag: ${tag.name}`} />
      <div className="hd-section-content hd-projects-list">
        {
          tag.projects.map(p => (
            <Project
              name={p.name}
              description={p.description}
              url={p.url}
              key={`${p.name}-project`}
              tags={p.tags}
            />
          ))
        }
      </div>
    </div>
  );
};

export default TagPage;
