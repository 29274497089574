import ArticulatingClock from "./articulating-clock";
import Bounce from "./bounce";
import CubeEditor from "./isometric-cube-editor";
import Devster from "./devster";
import DrawArm from "./draw-arm";
import Gravity from "./gravity";

const StorybookLink = {
  component: null,
  name: "Storybook Documentation",
  description: "View Storybook documentation for heyer.dev",
  url: "https://www.heyer.dev/storybook/",
  tags: "Documentation",
  route: false
}

const projects = [
  Bounce,
  CubeEditor,
  Devster,
  DrawArm,
  ArticulatingClock,
  StorybookLink,
  Gravity
];

export default projects;
