import React from "react";

const InputContainer = ({ label, children }) => (
  <fieldset className="input-container">
    {label && (<legend>{label}</legend>)}
    {children}
  </fieldset>
);

export default InputContainer;
