import React, {FC} from "react";
import ProjectHeader from "../project-header";
import ProjectLink from "./project-link";
import TagLink from "./tag-link";
import SBLink from "./sb-link";
import { Tag, Project } from "../types";
import "./sitemap.css";

const sbLinks = require("../../sb-sitemap.json");

const getProjectLinks = (projects: Array<Project>) =>
  projects.map(({ name, url }) => <ProjectLink key={name} name={name} url={url} />);

const getTagLinks = (tags: Array<Tag>) =>
  Object.values(tags)
    .sort((t1, t2) => {
      const countDiff = t2.count - t1.count;
      if (countDiff) {
        return countDiff;
      }

      return t2.name.localeCompare(t1.name);
    })
    .map(({name, id, count}) => (
      <TagLink key={id} name={name} id={id} count={count} />
    ));

const getSBLinks = (stories: Array<{url: string; name: string;}>) => {
  const result: Array<JSX.Element> = [];
  let curr: Array<JSX.Element> = [];
  let parent: {url: string; name: string;};

  stories.forEach(s => {
    if (s.name.indexOf("/") !== -1) {
      if (curr.length) {
        result.push(
          <SBLink key={parent.name} {...parent}>
            {curr}
          </SBLink>
        );

        curr = [];
      }

      parent = s;
    }
    else {
      curr.push(<SBLink key={s.name} {...s} />);
    }
  });

  return result;
};

interface SitemapProps {
  projects: Array<Project>;
  tags: Array<Tag>;
}

const Sitemap: FC<SitemapProps> = ({ projects = [], tags = [] }) => (
  <div className="hd-project hd-sitemap">
    <ProjectHeader title="Sitemap" />

    <ProjectHeader title="Projects" subhead back={false} />
    <div className="hd-project-content">
      <ul>
        {getProjectLinks(projects)}
      </ul>
    </div>

    <ProjectHeader title="Tags" subhead back={false} />
    <div className="hd-project-content">
      <ul>
        {getTagLinks(tags)}
      </ul>
    </div>

    <ProjectHeader title="Storybook" subhead back={false} />
    <div className="hd-project-content hd-sitemap-stories">
      <ul>
        {getSBLinks(sbLinks)}
      </ul>
    </div>
  </div>
);

export default Sitemap;
