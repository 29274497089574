import React, {FC} from "react";
import Section from "../section";

/**
 * The about section
 */
const About: FC = () => (
  <Section title="about">
    Welcome to the repository of creations, projects and ideas from the mind of Brandon Heyer. Brandon is currently a Principal Engineer at Social Tables specializing in front end technologies. With a background in computer science and graphic design, these projects are an exploration of that intersection and more.
  </Section>
);

export default About;
