
import React, { useRef, useEffect, useState, useMemo } from "react";
import IsometricCanvas from "./isometric-canvas";

export default function Cube(props) {
  const one = props.scale;
  const two = one * 2;
  const full = props.size * one;

  const ax = props.addr.x;
  const ay = props.addr.y;
  const az = props.addr.z;

  const canvas = useRef();
  const [ctx, setCtx] = useState();

  const isometricCanvas = useMemo(
    () => new IsometricCanvas(ctx, props.scale, "#555555", "#e9e9e9"),
    [ctx, props.scale]
  );

  const styles = useMemo(
    () => ({
      left: (props.x / 2) * full,
      top: props.y * full / 4,
      position: props.relative ? "relative" : "absolute"
    }),
    [props.x, props.y, full, props.relative]
  );

  useEffect(() => {
    canvas.current && setCtx(canvas.current.getContext("2d"));

    if (ctx) {
      const half = full / 2;
      const fourth = full / 4;
      const scaledFourth = fourth / one;
      const threeFourths = 3 * fourth;

      isometricCanvas.fillTop(one, fourth - one, scaledFourth, "#dddddd");
      isometricCanvas.fillSide(one, fourth, true, scaledFourth, "#bbbbbb");
      isometricCanvas.fillSide(half - one, half - one, false, scaledFourth, "#999999");

      // Left Vertical
      isometricCanvas.drawLine(0, fourth, one, half, ax === 0 && ay === props.board[az].length - 1);

      // Right Vertical
      isometricCanvas.drawLine(full - one, fourth, one, half, ay === 0 && ax === props.board[az][ay].length - 1);

      // Center Vertical
      isometricCanvas.drawLine(half - one, half, one, half, false);

      // Left Bottom
      isometricCanvas.drawDiagonal(0, threeFourths, true, scaledFourth, az === props.board.length - 1 && ay === props.board[az].length - 1);

      // Right Bottom
      isometricCanvas.drawDiagonal(half - two, full - one, false, scaledFourth, az === props.board.length - 1 && ax === props.board[az][ay].length - 1);

      // Top Outer Left
      isometricCanvas.drawDiagonal(0, fourth - one, false, scaledFourth, ax === 0 && az === 0);

      // Top Outer Right
      isometricCanvas.drawDiagonal(half - two, 0, true, scaledFourth, az === 0 && ay === 0);

      // Top Inner Left
      isometricCanvas.drawDiagonal(0, fourth, true, scaledFourth, false);

      // Top Inner Right
      isometricCanvas.drawDiagonal(half - two, half - one, false, scaledFourth, false);
    }
  }, [ctx, ax, ay, az, full, props.board, one, two, isometricCanvas]);

  return (
    <canvas
      ref={canvas}
      width={props.size * props.scale}
      height={props.size * props.scale}
      style={styles}
    />
  );
}

Cube.defaultProps = {
  size: 128,
  scale: 2,
  x: 0,
  y: 0,
  addr: { x: 0, y: 0, z: 0 },
  board: [[[1]]],
  relative: false
};
