import { BaseEntity, universalGravitation, Point, Vector } from "2d-engine/src";

export default class PlanetEntity extends BaseEntity {
  constructor(options) {
    super(options);

    this.options = options;
    this.fixed = options.fixed;
    this.radius = options.radius || 50;
    this.mass = options.mass || 1000;
    this.fill = options.fill || '#ff0000';
  }

  initializeProperties(options) {
    options.update = "advanced";

    super.initializeProperties(options);

    if (options.headingDegrees !== undefined) {
      this.heading.y = 0;
      this.heading.x = options.headingMagnitude === undefined ? 1 : options.headingMagnitude;
      this.heading.rotate(options.headingDegrees * Math.PI / 180);
    }
  }

  startingPosition(options) {
    if (options.positionDegrees !== undefined) {
      const pos = new Vector((options.positionMagnitude || 10), 0);

      pos.rotate(options.positionDegrees * Math.PI / 180);

      return new Point(
        (options.engine.xMax / 2) - pos.x,
        (options.engine.yMax / 2) + pos.y
      );
    }

    return super.startingPosition(options);
  }

  initialize() {
    universalGravitation.initialize(this, this.options);
  }

  // preUpdatePosition(delta) {
  //   super.preUpdatePosition(delta);
  //
  //   !this.fixed && universalGravitation.preUpdatePosition(this);
  // }

  updateWithOther(delta, other) {
    // super.updateWithOther(delta, other);

    if (other !== this) {
      !this.fixed && universalGravitation.updateWithOther(this, other);
    }
  }

  onCollision() {

  }

  updatePosition(delta) {
    if (!this.fixed) {
      universalGravitation.updatePosition(this, delta);
      this.forceVector.x = 0;
      this.forceVector.y = 0;
    }
  }

  render(canvas) {
    this.element = canvas.makeCircle(
      this.xScale(this.pos.x),
      this.yScale(this.pos.y),
      this.xScale(this.radius)
    );

    this.element.fill = this.fill;
    this.element.stroke = "none";

    this.translateByPoint(this.pos);
  }
}
