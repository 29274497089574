import React from "react";

import Engine from "./engine";
import TwoBallCollision from "./two-ball-collision";
import ProjectHeader from "../../ui/project-header";

const RATIO = 5;
const WORLD_WIDTH = 600;
const WORLD_HEIGHT = WORLD_WIDTH / RATIO;
const RADIUS = 25;

const examples = [
  [
    (w, h) => ({
      radius: RADIUS,
      headingX: 3,
      positionX: 1 / 5 * w,
      positionY: 1 / 2 * h
    }),
    (w, h) => ({
      radius: RADIUS,
      positionX: 3 / 5 * w,
      positionY: 1 / 2 * h
    })
  ],
  [
    (w, h) => ({
      radius: RADIUS / 2,
      headingX: 3,
      positionX: 1 / 5 * w,
      positionY: 3 / 10 * h
    }),
    (w, h) => ({
      radius: RADIUS,
      positionX: 3 / 5 * w,
      positionY: 3 / 10 * h
    }),
    (w, h) => ({
      radius: RADIUS,
      headingX: 3,
      positionX: 1 / 5 * w,
      positionY: 7 / 10 * h
    }),
    (w, h) => ({
      radius: RADIUS / 2,
      positionX: 3 / 5 * w,
      positionY: 7 / 10 * h
    })
  ],
  [
    (w, h) => ({
      radius: RADIUS / 1.5,
      headingX: 3,
      positionX: 1/5 * w,
      positionY: 13/32 * h
    }),
    (w, h) => ({
      radius: RADIUS / 1.5,
      positionX: 2.5/5 * w,
      positionY: 17/32 * h
    })
  ],
  [
    (w, h) => ({
      radius: RADIUS,
      headingX: 3,
      positionX: RADIUS,
      positionY: h / 2
    }),
    (w, h) => ({
      radius: RADIUS,
      positionX: w / 2,
      positionY: h / 2
    }),
    (w, h) => ({
      radius: RADIUS,
      headingX: -2,
      positionX: w - RADIUS,
      positionY: h / 2
    })
  ]
];

const BouncePage = () => (
  <div className="hd-project">
    <ProjectHeader title={BounceProject.name} />
    <div className="hd-project-content" style={{textAlign: "center"}}>
      <Engine />
    </div>

    <ProjectHeader subhead back={false}>
      Learn More
    </ProjectHeader>
    <div className="hd-project-learn">
      This is an
      {" "}<a href="https://en.wikipedia.org/wiki/Elastic_collision">elastic collision</a>{" "}
      simulation, including gravity. It is leveraging my
      {" "}<a
        href="https://github.com/brandonheyer/2d-engine"
        title="2D Game Engine"
      >2D Game Engine</a>.{" "}
      More information, including customization of settings, will follow!
      <ul>
        <li>
          You can view the JavaScript backing the above logic at Github: <a href="https://github.com/brandonheyer/2d-engine/blob/master/packages/engine/src/forces/bounce.js">Optimized Ellastic Collision Javascript Code</a><br />
        </li>
        <li>
          Alternativelty, this code more closely follows the math outlined in the Wikipedia article: <a href="https://github.com/brandonheyer/2d-engine/blob/master/packages/engine/src/forces/bounce.js">Standard Ellastic Collision Javascript Code</a><br />
        </li>
      </ul>
    </div>

    <ProjectHeader subhead={2} back={false}>
      Two Ball Elastic Collision
    </ProjectHeader>
    <div className="hd-project-learn">
      Additional information coming soon...
    </div>

    <ProjectHeader subhead={3} back={false}>
      Between Balls of Equal Weight
    </ProjectHeader>
    <TwoBallCollision
      ballOptions={examples[0]}
      width={WORLD_WIDTH}
      height={WORLD_HEIGHT}
    />

    <ProjectHeader subhead={3} back={false}>
      Between Balls of Different Weights
    </ProjectHeader>
    <TwoBallCollision
      ballOptions={examples[1]}
      width={WORLD_WIDTH}
      height={WORLD_HEIGHT * 2}
    />

    <ProjectHeader subhead={3} back={false}>
      Two Dimensional Collision
    </ProjectHeader>
    <TwoBallCollision
      ballOptions={examples[2]}
      width={WORLD_WIDTH}
      height={WORLD_HEIGHT * 2}
    />


    <ProjectHeader subhead={2} back={false}>
      Multi-Ball Elastic Collision
    </ProjectHeader>
    <div className="hd-project-learn">
      Additional information coming soon...
    </div>

    <ProjectHeader subhead={3} back={false}>
      Between Balls of Equal Weight
    </ProjectHeader>
    <TwoBallCollision
      ballOptions={examples[3]}
      width={WORLD_WIDTH}
      height={WORLD_HEIGHT}
    />
  </div>
);

const BounceProject = {
  component: BouncePage,
  name: "Bounce Physics",
  description: "A simple implementation of bounce physics using my 2d-game engine",
  url: "/bounce",
  tags: "Physics, Animation, Game, Simulation, Force"
};

export default BounceProject;
