import React, { useRef, useState, useMemo, useEffect } from "react";

const colors = [
  "rgba(255,0,0,1)",
  "rgba(0,255,0,1)",
  "rgba(0,0,255,1)",
  "rgba(255,0,255,1)",
  "rgba(0,255,255,1)",
];

const SVG = ({ positions, onClick = () => {}, animate = false, selected = false }) => {
  const [index, setIndex] = useState(0);
  const [running, setRunning] = useState(animate);
  const handle = useRef();

  const paths = useMemo(() => {
    let d = "M 150 150 ";
    let hasNaN = false;

    const position = animate ? positions[index] : positions;

    const ps = position.map((pos, i) => {
      // if (i === position.length - 1) {
      //   return null;
      // }

      d += `L ${pos.x + 150} ${pos.y + 150}`;

      if (isNaN(pos.x + 150)) {
        hasNaN = true;
      }

      const res = (<path fill="none" strokeWidth="6" stroke={colors[i]} d={d} />);
      d = `M ${pos.x + 150} ${pos.y + 150} `;

      return res;
    });

    if (running && animate) {
      if (handle.current) {
        clearTimeout(handle.current);
      }

      handle.current = setTimeout(() => (index === positions.length - 1) ? setIndex(0) : setIndex(index + 1), 50);
    }

    return hasNaN ? [] : ps;
  }, [running, animate, positions, index]);

  useEffect(() => {
    if (!running && handle.current) {
      clearTimeout(handle.current);
    }
  });

  if (!animate && !paths) {
    return null;
  }

  return (
    <div onClick={onClick}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="25 25 250 250">
        <circle cx="150" cy="150" r="40" stroke="rgba(0,0,0,0.5)" strokeWidth="4" fill={selected ? "rgba(0,0,0,0.125)" : "none"} />
        <circle cx="150" cy="150" r="80" stroke="rgba(0,0,0,0.5)" strokeWidth="4" fill={selected ? "rgba(0,0,0,0.125)" : "none"} />
        {paths}
        {animate && (<text x="150" y="270" style={{textAnchor: "middle"}}>{Math.floor(index / 60)}:{index % 60}</text>)}
      </svg>

      {animate && (
        <div>
          <button onClick={() => setIndex(index === 0 ? positions.length - 1 : index - 1)}>
            &lt;&lt;&lt;
          </button>
          {running && (<button onClick={() => setRunning(false)}>Pause</button>)}
          {!running && (<button onClick={() => setRunning(true)}>Resume</button>)}
          <button onClick={() => setIndex(index === positions.length - 1 ? 0 : index + 1)}>
            &gt;&gt;&gt;
          </button>
        </div>
      )}
    </div>
  );
}

export default SVG;
