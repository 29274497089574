import React, {FC} from "react";

export interface DevsterProps {
  /**
   * The scaling factor
   */
  scale?: number;

  /**
   * Which way Devster is looking
   */
  direction?: "up" | "down" | "left" | "right";
}

/**
 * An SVG React Component for displaying the Devster Mascot!
 */
const Devster: FC<DevsterProps> = ({ direction, scale = 1}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={scale * 91.03} height={scale * 94.16} viewBox={`0 0 ${scale * 91.03} ${scale * 94.16}`}>
    <g transform={`scale(${scale})`}>
      <path d="M91,33.94C91,10.43,70.37,0,45.51,0S0,10.43,0,33.94C0,49.09,7.77,59.12,20.46,64.35c-1,3.91-2.24,7.11-3.21,10.34-1,3.4-3.17,6.83-3.17,11.16,0,3.15,6.53,8.31,9.7,8.31s4.37-1,7.59-2.09A48.23,48.23,0,0,1,45.51,90a54.76,54.76,0,0,1,14.82,2.1c3.45,1.05,4.64,2.09,6.9,2.09s9.71-5.16,9.71-8.31c0-4.33-2.15-7.76-3.17-11.16-1-3.23-2.19-6.43-3.22-10.34C83.26,59.12,91,49.09,91,33.94Z" fill="#0e0e0e"/>

      {
        (() => {
          switch (direction) {
            case "up":
              return (
                <g>
                  <path d="M45.51,4.6c-18.61,0-34.77,8.49-34.77,24.46s14.69,20.1,34.77,20.1S80.29,45,80.29,29.06,64.12,4.6,45.51,4.6Z" fill="#fff"/>
                  <path d="M42.23,23c0,7.29-4,15.67-10.64,15.67S18.15,30.24,18.15,23,24.94,11.3,31.59,11.3,42.23,15.66,42.23,23Z" fill="#0e0e0e"/>
                  <path d="M72.86,23c0,7.29-6.79,15.67-13.43,15.67S48.78,30.24,48.78,23s4-11.65,10.65-11.65S72.86,15.66,72.86,23Z" fill="#0e0e0e"/>
                </g>
              );

            case "down":
              return (
                <g>
                  <path d="M45.51,64.16c18.61,0,34.78-8.48,34.78-24.45S65.6,19.6,45.51,19.6,10.74,23.73,10.74,39.71,26.9,64.16,45.51,64.16Z" fill="#fff"/>
                  <path d="M43.36,44c-.63,7.26-5.34,15.26-12,14.68s-12.65-9.52-12-16.78,7.77-11,14.4-10.44S44,36.75,43.36,44Z" fill="#0e0e0e"/>
                  <path d="M71.64,41.91c.64,7.26-5.39,16.2-12,16.78S48.29,51.27,47.65,44s3-12,9.59-12.54S71,34.65,71.64,41.91Z" fill="#0e0e0e"/>
                </g>
              );

            case "left":
              return (
                <g>
                  <path d="M4,34.09C4,17.31,21,10.5,40.52,10.5S72,17.31,72,34.09v.67c0,16.77-12,23.58-31.51,23.58S4,51.53,4,34.76Z" fill="#fff"/>
                  <path d="M27.66,32.91c0,7.29,3.79,14.67,10.44,14.67S48.74,40.2,48.74,32.91s-4-11.65-10.64-11.65S27.66,25.62,27.66,32.91Z" fill="#0e0e0e"/>
                  <path d="M8.79,33.07c0,6.54,3,13.17,8.33,13.17s8.49-6.63,8.49-13.17-3.18-10.46-8.49-10.46S8.79,26.52,8.79,33.07Z" fill="#0e0e0e"/>
                </g>
              );

            case "right":
              return (
                <g>
                  <path d="M87,34.09c0-16.78-17-23.59-36.52-23.59S19,17.31,19,34.09v.67c0,16.77,12,23.58,31.51,23.58S87,51.53,87,34.76Z" fill="#fff"/>
                  <path d="M63.36,32.91c0,7.29-3.78,14.67-10.43,14.67S42.29,40.2,42.29,32.91s4-11.65,10.64-11.65S63.36,25.62,63.36,32.91Z" fill="#0e0e0e"/>
                  <path d="M82.24,33.07c0,6.54-3,13.17-8.33,13.17s-8.5-6.63-8.5-13.17,3.19-10.46,8.5-10.46S82.24,26.52,82.24,33.07Z" fill="#0e0e0e"/>
                </g>
              );

            default:
              return (
                <g>
                  <path d="M82,34.09c0-16.78-17-23.59-36.52-23.59S9,17.31,9,34.09v.67C9,51.53,26,58.34,45.51,58.34S82,51.53,82,34.76Z" fill="#fff"/>
                  <path d="M43.24,32.91c0,7.29-5,14.67-11.65,14.67S19.16,40.2,19.16,32.91s5.78-11.65,12.43-11.65S43.24,25.62,43.24,32.91Z" fill="#0e0e0e"/>
                  <path d="M71.86,32.91c0,7.29-5.78,14.67-12.43,14.67S47.79,40.2,47.79,32.91s5-11.65,11.64-11.65S71.86,25.62,71.86,32.91Z" fill="#0e0e0e"/>
                </g>
              );
          }
        })()
      }
    </g>
  </svg>
);

export default Devster;
