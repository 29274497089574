import React from "react";
import encodeLabel from "./encode-label"

const InputLabel = ({htmlFor = '', label, className = ''}) => {
  if (label) {
    return (
      <label
        className={`input-label ${className}`}
        htmlFor={htmlFor || encodeLabel(label)}
      >
        {label}
      </label>
    )
  }

  return null;
}

export default InputLabel;
