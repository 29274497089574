import {useState, useMemo, useCallback, useEffect} from "react";
import { TwoEngine } from "2d-engine/src";

const reset = (engine, defaultValues, setOptions, beforeStart = () => {}) => () => {
  engine.stop();
  engine.clear();

  setOptions((prev)=>({...prev}));
  beforeStart();
}

const setValue = (setOptions, defaultValues, afterSet, engine, options) =>
  (key) => (v) => {
    if (isNaN(v)) {
      v = defaultValues.key;
    }

    afterSet(key, v, engine, options);

    setOptions((prev) => ({
      ...prev,
      [key]: v
    }));
};

const useEngine = ({ getClientWidth, getClientHeight, worldWidth, worldHeight, id, ...otherEngineProps}, defaultValues = {}, beforeStart = () => {}, afterSet = () => {}) => {
  const [options, setOptions] = useState(Object.assign({}, defaultValues));
  const [engine, setEngine] = useState();
  const [engineContainer, setRef] = useState(null);

  const internalReset = useCallback(reset(engine, defaultValues, setOptions), [engine, defaultValues, beforeStart]);
  const internalSetValue = useCallback(setValue(setOptions, defaultValues, afterSet, engine, options), [defaultValues, afterSet, engine, options]);

  useEffect(() => {
    if (engineContainer) {
      if (engine) {
        console.log("RESETTING!");
        internalReset();
      } else {
        const engine = new TwoEngine(
          `.engine-${id}`,
          getClientWidth(engineContainer), getClientHeight(engineContainer),
          worldWidth, worldHeight,
          otherEngineProps
        );

        setEngine(engine);
      }
    }
  }, [engine, engineContainer, id, worldWidth, worldHeight, getClientWidth, getClientHeight]);

  const setters = useMemo(() => {
    return Object.keys(defaultValues).reduce((acc, k) => {
      acc[k] = internalSetValue(k);

      return acc;
    }, {});
  }, [defaultValues, internalSetValue]);

  return [options, engine, setRef, internalReset, setters];
};

export default useEngine;
