import React, {FC} from "react";
import { Link } from "react-router-dom";

import { Project as TProject } from "../types";
import ProjectTag from "./project-tag";
import "./project.css";

const getTagsArray = (tags: string): Array<string> => tags.split(",").map(t => t.trim());

const Project: FC<TProject> = ({
  url,
  description,
  name,
  tags,
  route = true
}) => (
  <div className="hd-project-item">
    { route && (<Link className="hd-project-name" to={url}>{name}</Link>) }
    { !route && (<a href={url} className="hd-project-name">{name}</a>) }

    {
      description && (
        <div className="hd-project-description">
          {description}
        </div>
      )
    }

    {
      tags && (
        <div className="hd-project-tags">
          {getTagsArray(tags).map(t => (<ProjectTag tag={t} />))}
        </div>
      )
    }
  </div>
);

export default Project;
