import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import projects from "./projects";

import Header from "./ui/header";
import About from "./ui/about";
import ProjectList from "./ui/project-list";
import Sitemap from "./ui/sitemap";
import TagPage from "./ui/tag-page";

import IconGithub from "./ui/svg/icon-github";

import getProjects from "./lib/get-projects";

import './main.css';

function Main() {
  const { routes, projectComponents, tags } = getProjects(projects);

  return (
    <Router
      forceRefresh={false}
    >
      <Switch>
        {routes}

        <Route path="/sitemap">
          <Sitemap projects={projects} tags={tags} />
        </Route>

        <Route
          path="/tags/:tag"
          children={({ match }) => (tags[match.params.tag] && (
           <TagPage tag={tags[match.params.tag]} />
         ))}
        />

        <Route path="/">
          <Header numProjects={projects.length} />
          <About />
          <ProjectList projects={projectComponents} />
        </Route>
      </Switch>

      <div className="hd-footer">
        <div className="hd-footer-links">
          <a href="https://github.com/brandonheyer">
            <IconGithub />
          </a>
        </div>
        <div className="hd-copyright">
          heyer.dev is a property of Heyer Designs. <br />
          <Link to="/sitemap">sitemap</Link>
        </div>
      </div>
    </Router>
  );
}

export default Main;
