import React from 'react';
import { useHistory } from "react-router-dom";

import CubeEditor from "../isometric/editor";
import ProjectHeader from "../../ui/project-header";
// import CanvasExample from "./canvas-example";

function CubeEditorPage(props) {
  const history = useHistory();
  const params = props.match.params;
  const paramSize = parseInt(params.size, 10);
  const paramScale = parseInt(params.scale, 10);

  return (
    <div className="hd-project">
      <ProjectHeader title="Isometric Cube Editor" />
      <div className="hd-project-content">
        <CubeEditor
          size={parseInt(paramSize, 10)}
          scale={parseInt(paramScale, 10)}
          onChange={(size, scale) => {
            if (
              size !== paramSize ||
              scale !== paramScale
            ) {
              history.replace(`/isometric-cube/${size}/${scale}`);
            }
          }}
        />
      </div>

      <ProjectHeader title="Learn More" subhead back={false}/>

      <div className="hd-project-learn">
        I have always loved pixel art, but never been very good at it. Except when making isometric cubes. Well, now I have a React component that can do that for me!<br /><br />
        There will be further examples of the individual steps taken within the component added here at a later time!
      </div>
    </div>
  )
};

// <CanvasExample
//   render={(isometricCanvas) => {
//     isometricCanvas.fillTop(one, fourth - one, scaledFourth, "#dddddd");
//   }}
// />

export default {
  component: CubeEditorPage,
  name: "Isometric Cube Editor",
  description: "Create isometic pixel-art cubes, using HTML Canvas and React",
  url: "/isometric-cube",
  path: "/isometric-cube/:size?/:scale?",
  tags: "HTML, Visual, React, JavaScript"
};
